<div class="dialog-title">
  <h1 class="text-2xl">{{ data.title || 'Select Image' }}</h1>
</div>

<div class="dialog-content">
  <div class="aspect-square">
    <group-ui-select-image [file]="file"></group-ui-select-image>
  </div>
</div>

<div class="dialog-actions">
  <button mat-stroked-button mat-dialog-close class="!bg-error">Cancel</button>
  <button
    mat-raised-button
    [mat-dialog-close]="file()"
    [disabled]="!file()"
    class="!bg-success"
  >
    Confirm
  </button>
</div>
