<input
  matInput
  [matAutocomplete]="auto"
  [formControl]="formControl"
  [placeholder]="field.props['placeholder'] || ''"
  name="search"
/>
<mat-autocomplete
  autoActiveFirstOption
  #auto="matAutocomplete"
  [displayWith]="displayWith"
>
  <mat-option *ngFor="let item of list | async" [value]="item.value">
    {{ item.label }}
  </mat-option>
</mat-autocomplete>
