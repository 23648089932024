import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  inject,
  OnDestroy,
} from '@angular/core';
import { CaptureService } from './service/capture.service';
import { StateService } from 'clocker/shared/services';
import { CaptureState } from './state/capture.state';

@Component({
  selector: 'app-capture',
  templateUrl: './capture.component.html',
  styleUrls: ['./capture.component.css'],
})
export class CaptureComponent implements AfterViewInit, OnDestroy {
  public readonly shared = {
    state: inject(StateService),
  };

  public readonly capture = {
    service: inject(CaptureService),
    state: inject(CaptureState),
  };

  @ViewChild('video')
  video!: ElementRef;

  @ViewChild('canvas')
  canvas!: ElementRef;

  ngAfterViewInit() {
    this.capture.service.startVideo(this.video.nativeElement);
  }

  ngOnDestroy() {
    this.capture.state.reset();
  }
}
