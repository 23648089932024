import { Injectable, inject } from '@angular/core';
import { JobState, ShiftState } from 'clocker/shared/state';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  public readonly shift = inject(ShiftState);
  public readonly job = inject(JobState);
}
