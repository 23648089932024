import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { StateService } from '..';
import environment from 'env';
import { JobModel, QrModel, ShiftModel } from 'clocker/shared/state';
export interface Capture {
  success: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiURL = environment.apiClockerURL;
  private adminApiURL = environment.apiURL;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(
    private http: HttpClient,
    private state: StateService,
  ) {}

  public auth(password: string): Observable<JobModel> {
    return this.http.post<JobModel>(
      `${this.apiURL}auth/job`,
      { password },
      this.httpOptions,
    );
  }

  public pin(id: string): Observable<ShiftModel> {
    this.setAuth();

    const data = {
      id,
      job_id: this.state.job.job_id(),
    };

    return this.http.post<ShiftModel>(
      `${this.apiURL}auth/pin`,
      data,
      this.httpOptions,
    );
  }

  public qrCode(code: string): Observable<QrModel> {
    return this.http
      .get<{ data: QrModel }>(
        `${this.adminApiURL}/api/qr/${code}`,
        this.httpOptions,
      )
      .pipe(map((res) => res.data));
  }

  public capture(image: string): Observable<Capture> {
    this.setAuth();

    const { id, start, night } = this.state.shift;

    const { job_id } = this.state.job;

    return this.http.post<Capture>(
      `${this.apiURL}photo`,
      {
        id: id(),
        start: start(),
        night: night(),
        job_id: job_id(),
        image,
      },
      this.httpOptions,
    );
  }

  private setAuth() {
    const token = this.state.job.token();

    if (!token) {
      this.httpOptions.headers =
        this.httpOptions.headers.delete('Authorization');
      return;
    }

    this.httpOptions.headers = this.httpOptions.headers.set(
      'Authorization',
      this.state.job.token(),
    );
  }
}
