<div class="wrapper">
  <div class="dialog-title !bg-transparent">
    <div>
      <h1 class="job">{{ data.job }}</h1>
      <p>{{ data.app }}</p>
    </div>

    <button
      mat-dialog-close
      mat-button
      class="!font-bold"
      (click)="service.auth()"
    >
      LOGOUT
    </button>
  </div>

  <div class="dialog-content !pb-0">
    <group-qr-setting></group-qr-setting>

    <form [formGroup]="form">
      <formly-form
        [form]="form"
        [fields]="fields"
        [model]="model"
        [options]="options"
        (modelChange)="change($event)"
      >
      </formly-form>
    </form>
  </div>

  <div class="dialog-actions">
    <button mat-raised-button mat-dialog-close class="!bg-error">CANCEL</button>

    <button
      cdkFocusInitial
      mat-raised-button
      class="!bg-success"
      mat-dialog-close
    >
      SUBMIT
    </button>
  </div>
</div>
