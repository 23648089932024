import { StorageService } from 'storage';
import { FormlyBase, FormlyModule } from 'formly';
import { QrSettingComponent } from 'components/qr/setting/src';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { PinSettingData } from './setting.type';
import { PinService } from '../../service/pin.service';
import { CLOCK_SETTING_MODEL } from './setting.model';

export interface ClockSettingModel {
  camera: string;
}

@Component({
  selector: 'app-pin-setting',
  standalone: true,
  imports: [
    CommonModule,
    FormlyModule,
    MatButtonModule,
    MatDialogModule,
    QrSettingComponent,
  ],
  templateUrl: './setting.component.html',
  styleUrl: './setting.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PinSettingComponent
  extends FormlyBase<ClockSettingModel>
  implements OnInit, OnDestroy
{
  public readonly data: PinSettingData = inject(MAT_DIALOG_DATA);
  public readonly service = inject(PinService);
  public readonly storage = inject(StorageService);

  ngOnDestroy(): void {
    this.destroy();
  }

  ngOnInit(): void {
    const setting = this.storage.get('clock-setting');
    this.init(CLOCK_SETTING_MODEL, setting);
  }

  change(event: ClockSettingModel) {
    this.storage.set('clock-setting', event);
  }
}
