import { formlyValidation } from 'libs/formly/src';
import { FormlyFieldConfig } from '@ngx-formly/core';

export const LOGIN_MODEL: FormlyFieldConfig[] = [
  {
    key: 'email',
    type: 'input',
    defaultValue: null,
    templateOptions: {
      type: 'text',
      placeholder: 'Email',
      label: 'Email',
      attributes: {
        'data-test': 'login-email',
      },
      required: true,
    },
    validators: {
      email: formlyValidation.email,
    },
  },
  {
    key: 'password',
    type: 'input',

    className: 'wrapper-field',
    templateOptions: {
      type: 'password',
      label: 'Password',
      attributes: {
        'data-test': 'login-password',
      },
      required: true,
    },
    validation: {
      messages: {
        required: formlyValidation.required,
      },
    },
  },
];

export const JOB_LOGIN_MODEL: FormlyFieldConfig[] = [
  {
    key: 'password',
    type: 'input',
    className: 'wrapper-field',
    templateOptions: {
      type: 'password',
      label: 'Job Password',
      attributes: {
        'data-test': 'job-password',
      },
      required: true,
    },
    validation: {
      messages: {
        required: formlyValidation.required,
      },
    },
  },
];

export const ID_LOGIN_MODEL: FormlyFieldConfig[] = [
  {
    key: 'password',
    type: 'input',
    className: 'wrapper-field',
    templateOptions: {
      type: 'password',
      label: 'ID Password',
      attributes: {
        'data-test': 'job-password',
      },
      required: true,
    },
    validation: {
      messages: {
        required: formlyValidation.required,
      },
    },
  },
];
