<div class="note">
  You don't have a clock in time. When did you start your shift?
</div>

<div class="actions">
  <ng-container>
    <button
      class="action bg-warning"
      (click)="shared.shift.outType(true)"
      data-test="clock-in-today"
    >
      {{ yesterday }}
    </button>

    <p class="my-4 md:mx-4">OR</p>

    <button
      class="action bg-warning"
      (click)="shared.shift.outType(false)"
      data-test="clock-in-yesterday"
    >
      {{ today }}
    </button>
  </ng-container>
</div>
