import { MatIconModule } from '@angular/material/icon';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { FormlyBase, FormlyModule } from 'formly';

interface DialogData<T> {
  title: string;
  fields: FormlyFieldConfig[];
  model: T;
  showButtonReset?: boolean;
}

@Component({
  selector: 'group-ui-form-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormlyModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './ui-form-dialog.component.html',
  styleUrl: './ui-form-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiFormDialogComponent<T = unknown>
  extends FormlyBase<T>
  implements OnInit, OnDestroy
{
  public dialogRef = inject(MatDialogRef<UiFormDialogComponent<T>>);
  public data = inject<DialogData<T>>(MAT_DIALOG_DATA);

  ngOnInit(): void {
    this.init(this.data.fields, this.data.model || {});
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  submit() {
    if (this.form.invalid) return;

    this.dialogRef.close(this.form.value);
  }

  reset() {
    this.dialogRef.close({ reason: 'reset' });
  }
}
