import { UiLoadingComponent } from 'ui-loading';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ApiService } from 'api';

@Component({
  selector: 'group-ui-select-image',
  standalone: true,
  imports: [CommonModule, MatButtonModule, UiLoadingComponent],
  templateUrl: './select-image.component.html',
  styleUrl: './select-image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectImageComponent implements OnDestroy {
  @Input({ required: true }) file = signal<File | string | undefined>(
    undefined,
  );
  @Input() accept: string = 'image/*';
  @Input() isSecureImage?: false;

  public readonly apiService = inject(ApiService);

  public readonly fileObjectURL = signal<string | undefined>(undefined);
  public readonly imgLoading = signal(false);

  constructor() {
    effect(
      () => {
        const file = this.file();

        if (!file) return;

        untracked(() => {
          this.createObjectURL(file as File);
        });
      },
      { allowSignalWrites: true },
    );
  }

  ngOnDestroy(): void {
    this.revokeObjectURL();
  }

  onFileChange(event: any) {
    const target = event.target as HTMLInputElement;
    const file = target.files?.item(0);

    if (!file) return;

    this.file.set(file);

    event.target.value = '';
  }

  createObjectURL(file: File | string) {
    if (typeof file === 'string') {
      this.imgLoading.set(true);

      if (!this.isSecureImage) {
        this.fileObjectURL.set(file);
        return;
      }

      this.getSecureImage(file);
      return;
    }

    this.revokeObjectURL();
    this.fileObjectURL.set(URL.createObjectURL(file as Blob));
  }

  revokeObjectURL() {
    if (this.fileObjectURL()) {
      URL.revokeObjectURL(this.fileObjectURL() as string);
    }
  }

  getSecureImage(link: string) {
    this.apiService.getSecureFile(link).then((res) => {
      this.revokeObjectURL();
      this.fileObjectURL.set(res);
      this.imgLoading.set(false);
    });
  }
}
