<form [formGroup]="form" (ngSubmit)="login()">
  <formly-form
    [form]="form"
    [fields]="fields"
    [options]="options"
    [model]="model"
  >
  </formly-form>
  <button
    mat-raised-button
    data-test="login-submit"
    class="w-full bg-primary-50"
    type="submit"
  >
    Login
  </button>
</form>
