import { Component, Input, WritableSignal, signal } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent {
  @Input() isLoading?: WritableSignal<boolean> = signal(false);

  close() {
    this.isLoading?.set(false);
  }
}
