import { MatIconModule } from '@angular/material/icon';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  computed,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';

import { KeyType, PinType, ScanType } from './qr-pin.type';
import { QrScanComponent } from 'components/qr/scan/src';
import { ScanError } from 'components/qr/scan/src/lib/qr-scan.type';
import { UI } from './qr-pin.model';
import { StorageService } from 'services/storage/src/lib/storage.service';
import { MatRippleModule } from '@angular/material/core';
import { Subject, takeUntil } from 'rxjs';
import { QrScanTimeoutService } from './store/qr-pin.timeout';

@Component({
  selector: 'group-qr-pin',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    QrScanComponent,
    MatSlideToggleModule,
    MatRippleModule,
  ],
  templateUrl: './qr-pin.component.html',
  styleUrl: './qr-pin.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class QrPinComponent implements OnInit, OnDestroy {
  @ViewChild(QrScanComponent) qrScan!: QrScanComponent;

  @Output() submitEvent = new EventEmitter<string>();
  @Output() scanEvent = new EventEmitter<string | ScanError>();
  @Input() paused = signal(true);

  public readonly back = 'DELETE';
  public readonly submit = 'LOGIN';
  public readonly keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, this.back, 0, this.submit];
  public readonly id = signal('84044');

  public pinType = signal<PinType>('key');
  public defaultCamera = signal<ScanType>('front');

  public readonly ui = computed(() => {
    return UI[this.pinType()];
  });

  public readonly timeout = inject(QrScanTimeoutService);

  private readonly storage = inject(StorageService);
  private timeoutSubscription = new Subject();

  constructor() {
    this.timeout.finishedTimeout
      .pipe(takeUntil(this.timeoutSubscription))
      .subscribe(() => {
        this.timeout.isScanning.set(false);
        this.qrScan?.scanner?.stop();
      });
  }

  ngOnInit(): void {
    this.getSetting();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.timeout.isScanning() && this.pinType() === 'scan') {
        this.qrScan.startScan();
      }
    }, 100);
  }

  ngOnDestroy(): void {
    this.timeoutSubscription.next(true);
    this.timeoutSubscription.complete();
  }

  public getSetting() {
    const screen = this.timeout.screen();

    if (screen) {
      this.pinType.set(screen.login);
      this.defaultCamera.set(screen.camera);
      return;
    }

    const reload = this.storage.get('reload');

    if (reload) {
      this.pinType.set(reload.login);
      this.defaultCamera.set(reload.camera);
      this.timeout.screen.set(reload);
      this.timeout.isScanning.set(true);
      this.storage.remove('reload');
      return;
    }

    const setting = this.storage.get('pin-setting');

    if (setting) {
      this.pinType.set(setting.login);
      this.defaultCamera.set(setting.camera);
    } else {
      this.storage.set('pin-setting', { login: 'key', camera: 'front' });
    }
  }

  public keyType(key: number | string): string {
    const mod = typeof key === 'string' ? `action ${key.toLowerCase()}` : '';
    const disabled =
      typeof key === 'string' && this.id().length === 0 ? 'disabled' : '';
    return `key ${mod} ${disabled}`;
  }

  public onPress(key: KeyType) {
    const length = this.id().length;

    this.id.update((id) => {
      switch (true) {
        case typeof key === 'number':
          return `${id}${length < 6 ? key : ''}`;
        case key === this.back:
          return id.slice(0, -1);
        default:
          this.onSubmit();
          return id;
      }
    });
  }

  public clearPin() {
    this.id.set('');
  }

  public onSubmit() {
    if (this.id().length === 0) return;
    this.submitEvent.emit(this.id());
  }

  public switchType() {
    this.clearPin();
    this.pinType.update((type) => (type === 'key' ? 'scan' : 'key'));
    this.setStateScreen();

    if (this.timeout.isScanning() && this.pinType() === 'scan') {
      setTimeout(() => {
        this.qrScan.startScan();
      }, 0);
    }
  }

  public startScan() {
    this.qrScan.startScan();
    this.timeout.startTimeout();
  }

  public scanError(error: ScanError) {
    this.timeout.startTimeout();
    this.scanEvent.emit(error);
  }

  public scanSuccess(code: string) {
    this.timeout.startTimeout();
    this.scanEvent.emit(code);
  }

  public switchCamera(event: MatSlideToggleChange) {
    this.defaultCamera.set(event.checked ? 'back' : 'front');
    this.setStateScreen();
  }

  private setStateScreen() {
    this.timeout.screen.set({
      login: this.pinType(),
      camera: this.defaultCamera(),
    });
  }
}
