<div class="picker-flex">
  <input
    matInput
    [min]="field.props['minDate']"
    [formControl]="formControl"
    [max]="field.props['maxDate']"
    [matDatepicker]="picker"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>
