<main class="wrapper">
  <h1 class="job" (click)="enterAdminPassword()">
    {{ shared.state.job.job_name() }}
  </h1>

  <h1 class="app">Clocker</h1>

  <group-qr-pin
    (submitEvent)="pin.service.loginEvent($event)"
    (scanEvent)="scanEvent($event)"
  ></group-qr-pin>
</main>

<app-toast
  [toast]="pin.state.toast"
  *ngIf="pin.state.toast().isShown"
></app-toast>

<app-loader [isLoading]="pin.state.isLoading"></app-loader>
