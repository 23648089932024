import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { storage } from 'clocker/shared/utils';

export const authGuard = () => {
  const router = inject(Router);

  try {
    const authed = storage.get('token');
    return authed ? true : router.navigate(['/auth']);
  } catch (error) {
    return router.navigate(['/auth']);
  }
};
