import {
  Injectable,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { Toast } from 'clocker/shared/components';
import { StateService } from 'clocker/shared/services';
import { State } from 'clocker/shared/utils';

const model = {
  img: '',
  isLoading: true,
  toast: {},
  stream: undefined,
};

@Injectable({
  providedIn: 'root',
})
export class CaptureState extends State<typeof model> {
  private state = inject(StateService);

  public readonly img = signal(model.img);
  public readonly isLoading = signal(model.isLoading);
  public readonly toast = signal(model.toast) as Toast;
  public readonly stream = signal(model.toast) as WritableSignal<
    MediaStream | undefined
  >;

  public readonly type = computed(() =>
    this.state.shift.start() ? 'IN' : 'OUT'
  );

  public readonly time = computed(() => {
    const d = new Date();
    const hours = d.getHours().toString().padStart(2, '0');
    const minutes = d.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  });

  public readonly model = computed(() => {
    return {
      time: this.time(),
      isLoading: this.isLoading(),
      toast: this.toast(),
    };
  });

  constructor() {
    super({ model });
  }
}
