import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'group-ui-loading',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './ui-loading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiLoadingComponent {
  @Input() icon: string = 'icon-[solar--refresh-circle-linear]';
  @Input() iconClass: string = 'text-white';
}
