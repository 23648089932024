@if (verify.clockStatus() === 'OUT') {
  <div class="my-10 text-xl">You have successfully clocked out</div>
} @else {
  <div class="location">
    <div class="location-item pb-5 border-b border-black/20">
      <p>Zone</p>

      <p>{{ shared.state.shift.deployment_zone() || 'N/A' }}</p>
    </div>

    <div class="location-item mt-5">
      <p>Position</p>

      <p>{{ shared.state.shift.position() || 'N/A' }}</p>
    </div>
  </div>
}
<button
  class="action bg-success !py-4 !text-xl md:!text-2xl"
  (click)="router.navigateByUrl('/pin')"
>
  CONTINUE... {{ countdown$ | async }}
</button>
