import { Injectable, computed, signal } from '@angular/core';
import { storage, State } from 'clocker/shared/utils';

const model = {
  token: storage.get('token', ''),
  job_id: storage.get('job_id', ''),
  job_name: storage.get('job_name', ''),
};

export type JobModel = typeof model;

@Injectable({
  providedIn: 'root',
})
export class JobState extends State<JobModel> {
  public readonly token = signal(model.token);
  public readonly job_id = signal(model.job_id);
  public readonly job_name = signal(model.job_name);

  public readonly model = computed(() => {
    return {
      token: this.token(),
      job_id: this.job_id(),
      job_name: this.job_name(),
    };
  });

  constructor() {
    super({ model, storage: true });
  }
}
