import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule as FormlyRootModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { formlyConfig } from './formly.config';
import { ImageFieldComponent } from './components/image-field/image-field.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { TimepickerComponent } from './components/timepicker/timepicker.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyRootModule.forRoot(formlyConfig),
    FormlyMaterialModule,
    FormsModule,
    ImageFieldComponent,
    DatepickerComponent,
    AutocompleteComponent,
    TimepickerComponent,
  ],
  exports: [
    ReactiveFormsModule,
    FormlyRootModule,
    FormlyMaterialModule,
    FormsModule,
  ],
})
export class FormlyModule {}
