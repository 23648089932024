import { PIN_SETTING_MODEL } from './qr-setting.model';
import { StorageService } from 'storage';
import { FormlyBase, FormlyModule } from 'formly';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';

export interface PinSettingModel {
  login: string;
  camera: string;
}
@Component({
  selector: 'group-qr-setting',
  standalone: true,
  imports: [CommonModule, FormlyModule],
  templateUrl: './qr-setting.component.html',
  styleUrl: './qr-setting.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class QrSettingComponent
  extends FormlyBase<PinSettingModel>
  implements OnInit, OnDestroy
{
  public readonly storage = inject(StorageService);

  ngOnDestroy(): void {
    this.destroy();
  }

  ngOnInit(): void {
    const setting = this.storage.get('pin-setting');
    this.init(PIN_SETTING_MODEL, setting);
  }

  change(event: PinSettingModel) {
    this.storage.set('pin-setting', event);
  }
}
