import { FormlyFieldConfig } from '@ngx-formly/core';

export const PIN_SETTING_MODEL: FormlyFieldConfig[] = [
  {
    key: 'login',
    type: 'select',
    props: {
      label: 'QR Default Login',
      options: [
        { label: 'Pin', value: 'key' },
        { label: 'QR', value: 'scan' },
      ],
    },
  },
  {
    key: 'camera',
    type: 'select',
    props: {
      label: 'QR Default Camera',
      options: [
        { label: 'Front', value: 'front' },
        { label: 'Back', value: 'back' },
      ],
    },
  },
];
