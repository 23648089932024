/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SelectImageComponent } from './select-image/select-image.component';

interface DialogData {
  title: string;
  accept?: string;
}

@Component({
  selector: 'group-ui-upload-image',
  standalone: true,
  templateUrl: './ui-upload-image.component.html',
  styleUrl: './ui-upload-image.component.scss',
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    SelectImageComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiUploadImageComponent {
  public readonly dialogRef = inject(MatDialogRef<UiUploadImageComponent>);
  public readonly data = inject<DialogData>(MAT_DIALOG_DATA);
  public readonly file = signal<File | undefined>(undefined);
}
