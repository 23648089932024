import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthComponent,
  PinComponent,
  VerifyComponent,
  CaptureComponent,
} from 'clocker/pages';
import { authGuard, pinGuard } from './guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'pin',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: AuthComponent,
  },
  {
    path: 'pin',
    component: PinComponent,
    canActivate: [authGuard],
  },
  {
    path: 'verify',
    component: VerifyComponent,
    canActivate: [pinGuard],
  },
  {
    path: 'capture',
    component: CaptureComponent,
    canActivate: [pinGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
