import { JOB_LOGIN_MODEL } from 'ui-login';
import { UiFormDialogComponent } from 'ui-form-dialog';
import { PinSettingComponent } from './components/setting/setting.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnDestroy, ViewChild, inject } from '@angular/core';
import { PinService } from './service/pin.service';
import { PinState } from './state/pin.state';
import { ApiService, StateService } from 'clocker/shared/services';
import { first } from 'rxjs';
import { QrPinComponent } from 'qr-pin';
import { ScanError } from 'qr-scan';
@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.css'],
})
export class PinComponent implements OnDestroy {
  @ViewChild(QrPinComponent) pinComponent!: QrPinComponent;

  public readonly dialog = inject(MatDialog);
  private api = inject(ApiService);

  public readonly pin = {
    service: inject(PinService),
    state: inject(PinState),
  };

  public readonly shared = {
    state: inject(StateService),
  };

  ngOnDestroy() {
    this.pin.state.reset();
  }

  scanEvent(code: string | ScanError) {
    if (!this.pin.state.isLoading() && typeof code === 'string') {
      this.pin.service
        .checkQR(code)
        .then((data) => {
          this.pin.service
            .loginEvent(
              this.pin.service.getStaffPin(data.staff_id, data.staff_type),
            )
            ?.catch(() => {
              this.pinComponent.startScan();
            });
        })
        .catch(() => {
          this.pinComponent.startScan();
        });
      return;
    }

    if (typeof code === 'object') {
      this.pin.service.notifyError(code.message, 5);
      return;
    }
  }

  enterAdminPassword() {
    const dialog = this.dialog.open(UiFormDialogComponent, {
      width: '400px',
      autoFocus: false,
      data: {
        title: 'Admin Password',
        fields: JOB_LOGIN_MODEL,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (!result) return;

      this.pin.state.isLoading.set(true);

      this.api
        .auth(result.password)
        .pipe(first())
        .subscribe({
          next: () => {
            this.pin.state.isLoading.set(false);
            this.openSetting();
          },
          error: () => {
            this.pin.state.isLoading.set(false);

            this.pin.state.toast.set({
              isShown: true,
              message: 'Password is incorrect',
              style: 'bg-error',
            });
          },
        });
    });
  }

  openSetting() {
    this.dialog
      .open(PinSettingComponent, {
        width: '600px',
        autoFocus: false,
        data: {
          job: this.shared.state.job.job_name(),
          app: 'Clocker',
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.pinComponent.getSetting();
      });
  }
}
