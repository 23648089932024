import { signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Subject } from 'rxjs';

export class FormlyBase<Model> {
  public form: FormGroup = new FormGroup({});
  public model!: Partial<Model>;
  public fields: FormlyFieldConfig[] = [];
  public options: FormlyFormOptions = {};
  public modelChanged = signal(false);
  public unsubscribeAll: Subject<unknown> = new Subject();

  public init(fields: FormlyFieldConfig[], model: Partial<Model> = {}): void {
    this.form = new FormGroup({});
    this.fields = fields;
    this.model = this.initModel(model, fields);
    this.options = {
      formState: {
        mainModel: this.model,
      },
    };
    this.unsubscribeAll = new Subject();
  }

  public destroy(): void {
    if (this.unsubscribeAll) {
      this.unsubscribeAll.next(true);
      this.unsubscribeAll.complete();
    }

    this.form.reset();

    if (this.options.resetModel) {
      this.options.resetModel();
    }

    this.model = {};
    this.options = {};
  }

  public modelChange() {
    this.modelChanged.set(true);
  }

  protected setValueForm(
    controls: string[],
    data: { [key: string]: string },
  ): void {
    controls.forEach((ctrl) => {
      this.form.controls[ctrl].setValue(data[ctrl]);
    });
  }

  public getFieldByKey(
    key: string,
    fields = this.fields,
  ): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }

      if (field.fieldGroup) {
        const existField = this.getFieldByKey(key, field.fieldGroup);

        if (existField) return existField;
      }
    }

    return null;
  }

  public mapFields(
    fields: FormlyFieldConfig[] = this.fields,
    result = new Map<string, FormlyFieldConfig>(),
  ) {
    for (const field of fields) {
      if (field.key) {
        result.set(field.key as string, field);
        continue;
      }

      if (field.fieldGroup) {
        this.mapFields(field.fieldGroup, result);
      }
    }

    return result;
  }

  protected initModel(
    data: Partial<Model>,
    fields: FormlyFieldConfig[] = this.fields,
  ) {
    const model: Partial<Model> = {};

    fields.forEach((field) => {
      if (field.key && data[field.key as keyof Model] != undefined) {
        model[field.key as keyof Model] = data[field.key as keyof Model];
      }
    });

    return model;
  }
}
