import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@Component({
  selector: 'lib-timepicker-field',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, NgxMaterialTimepickerModule],
  templateUrl: './timepicker.component.html',
  styleUrl: './timepicker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimepickerComponent extends FieldType<FieldTypeConfig> {
  onTimeChange(event: string) {
    this.formControl.setValue(event);
  }
}
