import {
  Component,
  Input,
  OnInit,
  WritableSignal,
  signal,
} from '@angular/core';
import { timeout } from 'clocker/shared/utils';

export interface ToastModel {
  isShown: boolean;
  message: string;
  style: string;
  timer: number;
  onClose?: () => unknown;
}

export type Toast = WritableSignal<Partial<ToastModel>>;

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css'],
})
export class ToastComponent implements OnInit {
  @Input({ required: true }) toast: Toast = signal({});

  #defaults = {
    isShown: false,
    message: '',
    style: 'bg-slate-800',
    timer: 0,
  };

  ngOnInit() {
    this.toast.set({
      ...this.#defaults,
      ...this.toast(),
    });

    const timer = Number(this.toast().timer);
    if (timer > 0) timeout(() => this.close(), timer);
  }

  public close() {
    this.toast().onClose ? this.toast().onClose?.() : null;
    this.toast.set({});
  }
}
