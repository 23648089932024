import { Injectable, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShiftService, StateService } from 'clocker/shared/services';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VerifyService {
  private router = inject(Router);
  private state = inject(StateService);
  private shift = inject(ShiftService);
  private route = inject(ActivatedRoute);

  public clockStatus = signal(null);

  public checkSuccess() {
    this.route.queryParams.pipe(first()).subscribe((params) => {
      const { success, status } = params;

      if (success) {
        this.clockStatus.set(status);
        this.shift.clear();
      }
    });
  }

  public continue(start: boolean) {
    this.state.shift.sync({ start });
    this.router.navigateByUrl('/capture');
  }

  public cancel() {
    if (!this.state.shift.start() && this.state.shift.out()) {
      return this.state.shift.sync({ out: false, start: true });
    }

    this.shift.clear();
    this.router.navigateByUrl('/pin');
  }
}
