import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService, ShiftService } from 'clocker/shared/services';
import { capture } from '../utils/capture';
import { CaptureState } from '../state/capture.state';
import { CAPTURE_ERRORS } from '../const/capture.errors';
import { StorageService } from 'storage';
import { QrScanTimeoutService } from 'components/qr/pin/src/lib/store/qr-pin.timeout';

@Injectable({
  providedIn: 'root',
})
export class CaptureService {
  private router = inject(Router);
  private api = inject(ApiService);
  private shift = inject(ShiftService);
  private state = inject(CaptureState);
  private readonly storage = inject(StorageService);
  private readonly qr = inject(QrScanTimeoutService);

  public startVideo(video: HTMLVideoElement) {
    const defaultCamera = this.storage.get('clock-setting');

    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: {
            ideal: defaultCamera.camera === 'back' ? 'environment' : 'user',
          },
        },
      })
      .then((stream) => {
        this.state.stream.set(stream);

        video.srcObject = stream;
        video.onloadedmetadata = () => {
          video.play();
          this.state.isLoading.set(false);
        };
      })
      .catch(() => {
        this.error(`Error accessing camera`);
      });
  }

  public screenshot(video: HTMLVideoElement, canvas: HTMLCanvasElement) {
    this.state.isLoading.set(true);

    this.state.img.set(capture(video, canvas));

    this.submit();
  }

  public cancel() {
    this.reset();
    this.router.navigateByUrl('/pin');
  }

  public submit() {
    this.api
      .capture(this.state.img() as string)
      .pipe(first())
      .subscribe({
        next: () => {
          this.redirectToVerify();
        },
        error: (res) => {
          this.state.isLoading.set(false);
          this.error(CAPTURE_ERRORS[res.error.code] || `Error updating shift`);
        },
      });
  }

  public reset() {
    this.shift.clear();
    this.state.isLoading.set(false);

    this.state.img.set('');
    this.state.toast.set({});

    if (!this.state.stream()) return;

    this.state
      .stream()
      ?.getTracks()
      .forEach((track) => track.stop());
    this.state.stream.set(undefined);
  }

  private error(message: string) {
    this.state.toast.set({
      isShown: true,
      message: message,
      style: 'bg-error',
    });
  }

  private redirectToVerify() {
    this.storage.set('reload', this.qr.screen());
    const baseURL = window.location.href.slice(0, this.router.url.length * -1);
    window.location.href =
      baseURL + `/verify?success=true&status=${this.state.type()}`;
  }
}
