import { UiLoadingComponent } from 'components/ui/loading/src';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  signal,
  ViewEncapsulation,
  effect,
  untracked,
  inject,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { ScanError } from './qr-scan.type';
import {
  ScannerQRCodeResult,
  NgxScannerQrcodeComponent,
  ScannerQRCodeDevice,
} from 'ngx-scanner-qrcode';

@Component({
  selector: 'group-qr-scan',
  standalone: true,
  imports: [CommonModule, NgxScannerQrcodeModule, UiLoadingComponent],
  templateUrl: './qr-scan.component.html',
  styleUrl: './qr-scan.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class QrScanComponent {
  @ViewChild('scanner') scanner!: NgxScannerQrcodeComponent;

  @Output() scanError = new EventEmitter<ScanError>();
  @Output() scanSuccess = new EventEmitter<string>();
  @Input() defaultCamera = signal('front');

  public isLoading = signal(false);
  public changeDetection = inject(ChangeDetectorRef);

  constructor() {
    effect(
      () => {
        this.defaultCamera();

        untracked(() => {
          this.isLoading.set(true);
          setTimeout(() => {
            this.isLoading.set(false);
          }, 1000);

          this.toggleCamera();
        });
      },
      { allowSignalWrites: true },
    );
  }

  startScan() {
    this.scanner.start((devices: ScannerQRCodeDevice[]) => {
      this.checkCurrentCamera(devices);
    });
  }

  checkCurrentCamera(devices: ScannerQRCodeDevice[]) {
    if (devices.length === 0) {
      return;
    }

    if (devices.length === 1 || this.defaultCamera() === 'front') {
      this.scanner.playDevice(devices[0].deviceId);
      return;
    }

    if (this.defaultCamera() === 'back') {
      const backCamera = devices.find(
        (device) => device.label === 'Back Camera',
      );
      this.scanner.playDevice(
        backCamera?.deviceId || devices[devices.length - 1].deviceId,
      );
    }
  }

  toggleCamera() {
    this.scanner.devices.subscribe((devices) => {
      this.checkCurrentCamera(devices);
    });
  }

  public onEvent(e: ScannerQRCodeResult[]): void {
    if (!e[0].value) {
      return;
    }
    this.scanner.stop();
    this.scanSuccess.emit(e[0].value);
  }
}
