<div class="card">
  <p class="note">
    Please enter a job password to unlock the app. Contact your manager, or the
    Stambridge Office if you do not know the password for this job.
  </p>

  <form (submit)="auth.service.submit()">
    <input
      type="text"
      name="password"
      data-test="job-password"
      [(ngModel)]="auth.state.password"
    />

    <button
      [disabled]="auth.state.password.length < 1"
      type="submit"
      data-test="login-submit"
    >
      Link Job
    </button>
  </form>
</div>

<app-toast
  [toast]="auth.state.toast"
  *ngIf="auth.state.toast().isShown"
></app-toast>

<app-loader [isLoading]="auth.state.isLoading"></app-loader>
