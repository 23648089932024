import { AuthModel, AuthType } from 'auth';
import { MatButtonModule } from '@angular/material/button';
import { FormlyModule } from 'libs/formly/src';
import { FormlyBase } from 'libs/formly/src';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LOGIN_MODEL } from './ui-login.model';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'group-ui-login',
  standalone: true,
  imports: [CommonModule, FormlyModule, MatButtonModule],
  templateUrl: './ui-login.component.html',
})
export class UiLoginComponent
  extends FormlyBase<AuthModel>
  implements OnInit, OnDestroy
{
  @Input() type: AuthType = 'admin';
  @Input() customFields?: FormlyFieldConfig[];
  @Output() loginEvent: EventEmitter<AuthModel> = new EventEmitter();

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  ngOnInit(): void {
    this.init(this.customFields || LOGIN_MODEL, {});
  }

  public login(): void {
    if (this.form.invalid) return;

    this.loginEvent.emit(this.form.value);
  }
}
