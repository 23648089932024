import { storage } from '../storage/storage';

export type BaseModel = {
  [key: string]: unknown;
};

export class State<TState> {
  #storage = false;
  #model = {} as BaseModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;

  constructor(options: { model: TState; storage?: boolean }) {
    this.#storage = options.storage || this.#storage;
    this.#model = options.model as BaseModel;
  }

  public sync<TModel>(data: Partial<TModel>) {
    Object.keys(data).forEach(key => {
      const inModel = Object.prototype.hasOwnProperty.call(this.#model, key);
      const hasSet = typeof this[key].set === 'function';
      const notFunction = typeof this[key] !== 'function';
      const value = data[key as never];

      switch (true) {
        case !inModel:
          break;
        case hasSet:
          this[key].set(!this.#storage ? value : storage.set(key, value));
          break;
        case notFunction:
          this[key] = data[key as keyof TModel];
          break;
      }
    });
  }

  public reset() {
    this.sync(this.#model);
  }

  public unsync() {
    Object.keys(this.#model).forEach(key => storage.remove(key));
  }
}
