import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FormlyMaterialModule } from '@ngx-formly/material';
import { FieldTypeConfig } from '@ngx-formly/core';
import { Observable, of, startWith, switchMap } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

export interface Data {
  label: string;
  value: string;
}

@Component({
  selector: 'lib-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FormlyMaterialModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
  ],
  templateUrl: './autocomplete.component.html',
  styleUrl: './autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  list!: Observable<Data[]>;

  constructor() {
    super();
  }

  ngOnInit() {
    this.list = this.formControl.valueChanges.pipe(
      startWith(''),
      switchMap((name) => {
        return this.filter(this.props['data'] ? this.props['data'] : [], name);
      }),
    );
  }

  filter(data: Data[], name: string) {
    return of(
      name
        ? data.filter(
            (item) =>
              item.label.toLowerCase().indexOf(name.toLowerCase()) === 0,
          )
        : data.slice(),
    );
  }

  displayWith(value: string): string {
    const item = this.props['data'].find((i: Data) => i.value === value);
    return item ? item.label : '';
  }
}
