import { LOAD_WASM } from 'ngx-scanner-qrcode';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QrPinComponent } from 'components/qr/pin/src';
import { MatIconModule } from '@angular/material/icon';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  PinComponent,
  CaptureComponent,
  AuthComponent,
  VerifyComponent,
  VerifyChoiceComponent,
  VerifyOutComponent,
} from 'clocker/pages';

import { ToastComponent, LoaderComponent } from 'clocker/shared/components';
import { QrSettingComponent } from 'qr-setting';
import { VerifyConfirmComponent } from 'clocker/pages/verify/components/confirm/confirm.component';

LOAD_WASM().subscribe();
@NgModule({
  declarations: [
    AppComponent,
    PinComponent,
    CaptureComponent,
    AuthComponent,
    ToastComponent,
    LoaderComponent,
    VerifyComponent,
    VerifyChoiceComponent,
    VerifyOutComponent,
    VerifyConfirmComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MatIconModule,
    QrPinComponent,
    QrSettingComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
