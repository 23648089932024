<main class="wrapper">
  <div class="banner">
    <div class="staff">
      <h1 class="name">{{ shared.state.shift.name() }}</h1>

      <h2 class="supplier">
        {{ shared.state.shift.supplier() }}
      </h2>

      <p class="id">ID: {{ shared.state.shift.id() }}</p>
    </div>

    <p class="time">{{ capture.state.time() }}</p>
  </div>

  <video #video class="h-full w-full" autoplay muted playsinline></video>

  <canvas #canvas class="hidden"></canvas>

  <div class="actions">
    <button
      class="action bg-error"
      (click)="capture.service.cancel()"
      data-test="capture-cancel"
    >
      CANCEL
    </button>

    <button
      class="action bg-success"
      data-test="clock"
      (click)="capture.service.screenshot(video, canvas)"
    >
      CLOCK {{ capture.state.type() }}
    </button>
  </div>
</main>

<app-toast
  [toast]="capture.state.toast"
  *ngIf="capture.state.toast().isShown"
></app-toast>

<app-loader [isLoading]="capture.state.isLoading"></app-loader>
