<div class="image-wrapper" (click)="fileInput.click()">
  @if (imgLoading()) {
    <div class="w-full h-full flex items-center justify-center py-2">
      <group-ui-loading></group-ui-loading>
    </div>
  } @else {
    @if (fileObjectURL(); as image) {
      <img
        [src]="image"
        class="max-w-full max-h-full object-cover"
        alt="Avatar"
        (load)="imgLoading.set(false)"
      />
    } @else {
      <button type="button" class="bg-gray-500" mat-button>Select Image</button>
    }
  }
</div>

<input
  #fileInput
  type="file"
  [accept]="accept"
  (change)="onFileChange($event)"
  hidden
/>
