<form [formGroup]="form">
  <formly-form
    [form]="form"
    [fields]="fields"
    [model]="model"
    [options]="options"
    (modelChange)="change($event)"
  >
  </formly-form>
</form>
