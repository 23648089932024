import moment from 'moment';

function date(diff = 0) {
  return moment().add(diff, 'days').format(dateFormat).toUpperCase();
}

export const dateFormat = 'ddd DD MMM';

export const today = date();

export const yesterday = date(-1);
