export function capture(video: HTMLVideoElement, canvas: HTMLCanvasElement) {
  const context = canvas.getContext('2d');

  const videoRatio = video.videoWidth / video.videoHeight;
  const photoRatio = 1;

  let width, height, x, y;

  switch (true) {
    case videoRatio > photoRatio:
      width = video.videoHeight * photoRatio;
      height = video.videoHeight;
      x = (video.videoWidth - width) / 2;
      y = 0;
      break;
    case videoRatio < photoRatio:
      width = video.videoWidth;
      height = video.videoWidth / photoRatio;
      x = 0;
      y = (video.videoHeight - height) / 2;
      break;
    default:
      width = video.videoWidth;
      height = video.videoHeight;
      x = 0;
      y = 0;
  }

  canvas.width = 200;
  canvas.height = 200;

  context?.drawImage(video, x, y, width, height, 0, 0, 200, 200);

  return canvas.toDataURL('image/jpg');
}
