<ngx-scanner-qrcode
  #scanner="scanner"
  (event)="onEvent($event)"
></ngx-scanner-qrcode>

@if (isLoading() || scanner.isLoading) {
  <div class="loading">
    <group-ui-loading></group-ui-loading>
  </div>
}
