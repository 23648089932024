import { ConfigOption } from '@ngx-formly/core';
import { ImageFieldComponent } from './components/image-field/image-field.component';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';

export const formlyConfig: ConfigOption = {
  types: [
    {
      name: 'image',
      component: ImageFieldComponent,
    },
    {
      name: 'timepicker',
      component: TimepickerComponent,
    },
    {
      name: 'date',
      component: DatepickerComponent,
    },
    {
      name: 'autocomplete',
      component: AutocompleteComponent,
    },
  ],
  wrappers: [],
  extensions: [],
  validationMessages: [
    {
      name: 'required',
      message: 'This field is required',
    },
  ],
};
