<div class="dialog-title">
  <h1 class="text-2xl">{{ data.title }}</h1>

  <button mat-icon-button mat-dialog-close>
    <mat-icon fontSet="icon-[solar--close-circle-linear]"></mat-icon>
  </button>
</div>

<div class="dialog-content">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <formly-form
      [form]="form"
      [fields]="fields"
      [model]="model"
      [options]="options"
      (modelChange)="modelChange()"
    >
    </formly-form>

    <button #submitBtn type="submit" [hidden]="true"></button>
  </form>
</div>

<div class="dialog-actions">
  <button mat-stroked-button mat-dialog-close class="!bg-error">Cancel</button>
  @if (data.showButtonReset) {
    <button mat-stroked-button class="!bg-error" (click)="reset()">
      Reset
    </button>
  }
  <button
    mat-raised-button
    data-test="dialog-form-confirm"
    class="!bg-success"
    (click)="submitBtn.click()"
  >
    Confirm
  </button>
</div>
