<p class="note" data-test="verify-note">
  {{
    shared.state.shift.start()
      ? 'Before continuing, let us know if you are:'
      : 'You clocked in for a shift already'
  }}
</p>

<div class="actions">
  <ng-container *ngIf="shared.state.shift.start()">
    <button
      class="action bg-success"
      (click)="verify.service.continue(true)"
      data-test="clock-in"
    >
      CLOCKING IN

      <span *ngIf="shared.state.shift.start_time()">
        {{ shared.state.shift.start_time() }}
      </span>
    </button>

    <p class="my-4 md:mx-4">OR</p>
  </ng-container>

  <button
    *ngIf="shared.state.shift.start()"
    class="action bg-warning"
    (click)="shared.shift.clockOut()"
    data-test="clock-out"
  >
    CLOCKING OUT

    <span *ngIf="shared.state.shift.end_time()">
      {{ shared.state.shift.end_time() }}
    </span>
  </button>

  <button
    *ngIf="!shared.state.shift.start()"
    class="action bg-success"
    (click)="verify.service.continue(false)"
    data-test="continue"
  >
    CONTINUE... {{ countdown$ | async }}
  </button>
</div>
