import { Subject } from 'rxjs';
import { Injectable, signal } from '@angular/core';
import { PinSetting } from '../qr-pin.type';

@Injectable({
  providedIn: 'root',
})
export class QrScanTimeoutService {
  public finishedTimeout = new Subject<void>();
  public isScanning = signal(false);
  public screen = signal<PinSetting | null>(null);
  private timeoutId!: ReturnType<typeof setTimeout>;

  startTimeout() {
    this.isScanning = signal(true);

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.finishedTimeout.next();
    }, 300000);
  }
}
