import { Component, OnDestroy, inject } from '@angular/core';
import { AuthService } from './service/auth.service';
import { AuthState } from './state/auth.state';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
})
export class AuthComponent implements OnDestroy {
  public readonly auth = {
    service: inject(AuthService),
    state: inject(AuthState),
  };

  ngOnDestroy() {
    this.auth.state.reset();
  }
}
