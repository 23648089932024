<div [class]="ui().wrapper">
  <div class="feedback">
    <button mat-icon-button (click)="switchType()">
      <mat-icon [fontSet]="ui().iconLeft"></mat-icon>
    </button>

    <span data-test="pin-id" class="w-full">
      {{ id() || ui().placeholder }}
    </span>

    <button
      [hidden]="pinType() === 'scan'"
      mat-icon-button
      (click)="clearPin()"
    >
      <mat-icon
        [fontSet]="ui().iconRight"
        [ngClass]="{ 'bg-white/20': id() === '' }"
      ></mat-icon>
    </button>
  </div>

  @if (pinType() === 'key') {
    <section class="keys">
      <button
        *ngFor="let key of keys"
        [class]="keyType(key)"
        (mousedown)="onPress(key)"
        data-test="pin-key"
      >
        {{ key }}
      </button>
    </section>
  }

  @if (pinType() === 'scan') {
    <div class="wrapper-scan">
      @if (!timeout.isScanning()) {
        <div class="scan-placeholder" matRipple (click)="startScan()">
          CLICK TO SCAN
        </div>
      }

      <group-qr-scan
        (scanError)="scanError($event)"
        (scanSuccess)="scanSuccess($event)"
        [defaultCamera]="defaultCamera"
      ></group-qr-scan>
    </div>

    <div class="switch-camera">
      <span>Front</span>
      <mat-slide-toggle
        (change)="switchCamera($event)"
        [checked]="defaultCamera() === 'back'"
        [hideIcon]="true"
        class="mx-10"
      ></mat-slide-toggle>
      <span>Back</span>
    </div>
  }
</div>
