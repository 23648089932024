import { Injectable, inject } from '@angular/core';
import { ShiftModel } from 'clocker/shared/state';
import { StateService } from 'clocker/shared/services';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ShiftService {
  public router = inject(Router);
  public state = inject(StateService);

  public set(data: Partial<ShiftModel>) {
    this.state.shift.sync(data);
  }

  public clear() {
    this.state.shift.reset();
    this.state.shift.unsync();
  }

  public clockOut() {
    this.state.shift.sync({
      start: false,
      out: true,
    });
  }

  public outType(night: boolean) {
    this.state.shift.sync({ night });

    this.router.navigateByUrl('capture');
  }
}
