const regex = (reg: RegExp, message: string) => {
  return {
    expression: (c: { value: string }) => reg.test(c.value),
    message: () => `Not a valid ${message}`,
  };
};

const regexOrNull = (regexCode: RegExp, message: string) => {
  return {
    expression: (c: { value: string }) => !c.value || regexCode.test(c.value),
    message: () => `Not a valid ${message}`,
  };
};

const required = () => {
  return 'This field is required!';
};

export const formlyValidation = {
  required: required(),
  date: regex(/^(\d{2}\/\d{2}\/\d{4})$/, 'datetime'),
  dateOrNull: regexOrNull(/^(\d{2}\/\d{2}\/\d{4})$/, 'datetime'),
  siaBadge: regex(/^\d{4}\s?\d{4}\s?\d{4}\s?\d{4}$/, 'badge number'),
  siaBadgeOrNull: regexOrNull(
    /^\d{4}\s?\d{4}\s?\d{4}\s?\d{4}$/,
    'badge number',
  ),
  drivingLicence: regex(
    /^([A-Z0-9]{5}\d[0156]\d([0][1-9]|[12]\d|3[01])\d[A-Z0-9]{3}[A-Z]{2})$/,
    'driving licence number',
  ),
  postcode: regex(
    /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/,
    'postcode',
  ),
  email: regex(
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/,
    'email',
  ),
  emailOrNull: regexOrNull(
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/,
    'email',
  ),
  phone: regex(
    /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\\#(\d{4}|\d{3}))?$/,
    'contact number',
  ),
  phoneOrNull: regexOrNull(
    /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\\#(\d{4}|\d{3}))?$/,
    'contact number',
  ),
  niNumber: regex(
    /^[A-z]{2}\s?[\d]{2}\s?[\d]{2}\s?[\d]{2}\s?[A-z]{1}$/,
    'National Insurance number',
  ),
  password: regex(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*_\-()@%&]).{6,}$/,
    'password',
  ),
  decimal: regex(/^\d{1,}\.\d{2}$/, 'number'),
  decimalOrNull: regexOrNull(/^\d{1,}\.\d{2}$/, 'number'),
  time: regexOrNull(/^\d{2}:\d{2}$/, 'time'),
  min: (min: number) => {
    return {
      expression: (c: { value: number }) => c.value >= min,
      message: () => `This value should be more than ${min}`,
    };
  },
  valueMoreThan0: () => {
    return {
      expression: (c: { value: number }) => {
        if (!c.value) return true;
        return c.value && c.value >= 0;
      },
      message: () => `This value should be more than 0`,
    };
  },
  max: (max: number) => {
    return {
      expression: (c: { value: number }) => c.value <= max,
      message: () => `This value should be less than ${max}`,
    };
  },
};
