export const PIN_ERRORS = [
  `Couldn't find ID. Please contact Stambridge Office`,
  'You already clocked out. Please contact Stambridge Office',
  'You are not eligible for this shift. Please contact Stambridge Office',
  'No shift found, please contact a supervisor',
];

export function getPinErrorMessage(code: number) {
  return PIN_ERRORS[code] || 'Pin error. Please contact Stambridge Office';
}
