import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  effect,
  signal,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { SelectImageComponent } from 'ui-upload-image';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'lib-image-field',
  standalone: true,
  imports: [CommonModule, SelectImageComponent, MatFormFieldModule],
  styleUrl: './image-field.component.scss',
  templateUrl: './image-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  public file = signal<File | string | undefined>(undefined);
  private unsubscribe$ = new Subject();

  constructor() {
    super();

    effect(
      () => {
        if (this.file() !== this.formControl.value) {
          this.formControl.setValue(this.file());
        }
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    if (this.formControl.value) {
      this.file.set(this.formControl.value);
    }

    this.formControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.file.set(value);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
