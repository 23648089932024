import { Component, OnInit, inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { StorageService } from 'storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private readonly swUpdate = inject(SwUpdate);
  public readonly storage = inject(StorageService);

  ngOnInit() {
    const setting = this.storage.get('clock-setting');

    if (!setting) {
      this.storage.set('clock-setting', { camera: 'front' });
    }

    this.swUpdate.versionUpdates.subscribe((event) => {
      if (event.type !== 'VERSION_READY') return;
      if (confirm('Update Version')) window.location.reload();
    });
  }
}
