import { Injectable, computed, signal } from '@angular/core';
import { Toast } from 'clocker/shared/components';
import { State } from 'clocker/shared/utils';

const model = {
  id: '',
  isLoading: false,
  toast: {},
};

@Injectable({
  providedIn: 'root',
})
export class PinState extends State<typeof model> {
  public readonly id = signal(model.id);
  public readonly isLoading = signal(model.isLoading);
  public readonly toast = signal(model.toast) as Toast;

  public readonly model = computed(() => {
    return {
      id: this.id(),
      isLoading: this.isLoading(),
      toast: this.toast(),
    };
  });

  constructor() {
    super({ model });
  }
}
