import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { authGuard } from 'clocker/core/guards';
import { storage } from 'clocker/shared/utils';

export const pinGuard = () => {
  const router = inject(Router);

  try {
    const authed = authGuard();
    const pin = storage.get('id');
    return authed && pin ? true : router.navigate(['/pin']);
  } catch (error) {
    return router.navigate(['/pin']);
  }
};
