import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { first, firstValueFrom } from 'rxjs';
import { ApiService, ShiftService } from 'clocker/shared/services';
import { storage } from 'clocker/shared/utils';
import { PinState } from '../state/pin.state';
import { getPinErrorMessage } from '../const/pin.errors';

@Injectable({
  providedIn: 'root',
})
export class PinService {
  private router = inject(Router);
  private api = inject(ApiService);
  private shift = inject(ShiftService);
  private state = inject(PinState);

  public checkQR(code: string) {
    this.state.isLoading.set(true);

    return firstValueFrom(this.api.qrCode(this.detectCode(code)).pipe(first()))
      .then((data) => {
        return data;
      })
      .catch((error) => {
        this.state.isLoading.set(false);

        this.notifyError(
          error.error.message || 'QR error. Please contact Stambridge Office', 5,
        );

        throw error;
      });
  }

  public loginEvent(pin: string) {
    this.state.isLoading.set(true);

    if (pin === '0000') {
      storage.clear();
      this.router.navigateByUrl('/auth');
      return;
    }

    return firstValueFrom(this.api.pin(pin).pipe(first()))
      .then((data) => {
        this.shift.set(data);
        this.state.isLoading.set(false);
        this.router.navigateByUrl('/verify');
        return data;
      })
      .catch((error) => {
        this.state.isLoading.set(false);
        this.notifyError(
          getPinErrorMessage(error.error.code),
          error.error.code === 3 ? 0 : 5,
        );
        throw error;
      });
  }

  public getStaffPin(id: number, type: string) {
    if (type === 'contractor') {
      return `0${id}`;
    }

    return `${id}`;
  }

  public notifyError(message: string, timer: number) {
    this.state.toast.set({
      isShown: true,
      message: message,
      style: 'bg-error',
      timer: timer,
    });
  }

  private detectCode(code: string) {
    return code.substring(code.indexOf(':') + 1);
  }

  public auth() {
    storage.clear();
    this.router.navigateByUrl('/auth');
  }
}
