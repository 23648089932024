class Storage {
  public set<TValue>(key: string, value: TValue): typeof value {
    localStorage.setItem(key, value as string);

    return value;
  }

  public get<TValue>(key: string, defaultValue?: TValue): TValue {
    let parse: TValue;

    try {
      parse = JSON.parse(localStorage.getItem(key) as string);
    } catch {
      parse = localStorage.getItem(key) as TValue;
    }

    return parse !== null ? parse : (defaultValue as TValue);
  }

  public remove(key: string) {
    localStorage.removeItem(key);
  }

  public clear() {
    localStorage.clear();
  }
}

export const storage = new Storage();
