import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from 'clocker/shared/services';
import { JobModel } from 'clocker/shared/state';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  public router = inject(Router);
  public state = inject(StateService);

  public set(data: Partial<JobModel>) {
    this.state.job.sync(data);
    this.router.navigateByUrl('pin');
  }

  public clear() {
    this.state.job.reset();
  }
}
