import { Injectable, inject } from '@angular/core';
import { first } from 'rxjs';
import { ApiService, JobService } from 'clocker/shared/services';
import { AuthState } from '../state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private api = inject(ApiService);
  private job = inject(JobService);
  private state = inject(AuthState);

  public submit() {
    this.state.isLoading.set(true);

    this.api
      .auth(this.state.password)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.state.password = '';
          this.state.isLoading.set(false);
          this.job.set(data);
        },
        error: () => {
          this.state.isLoading.set(false);

          this.state.toast.set({
            isShown: true,
            message: `Couldn't find that job. Please contact Stambridge Office`,
            style: 'bg-error',
          });
        },
      });
  }
}
