<div class="wrapper">
  <div class="card text-center">
    <div class="id">
      {{ shared.state.shift.id() }}
    </div>

    <h1 class="name">{{ shared.state.shift.name() }}</h1>

    <h2 class="supplier">
      {{ shared.state.shift.supplier() }}
    </h2>

    @if (verify.service.clockStatus()) {
      <app-verify-confirm />
    }

    @if (!shared.state.shift.out() && !verify.service.clockStatus()) {
      <app-verify-choice />
    }

    @if (
      !shared.state.shift.start() &&
      shared.state.shift.out() &&
      !verify.service.clockStatus()
    ) {
      <app-verify-out />
    }

    @if (!verify.service.clockStatus()) {
      <button
        class="cancel"
        (click)="verify.service.cancel()"
        data-test="verify-cancel"
      >
        CANCEL
      </button>
    }
  </div>
</div>
