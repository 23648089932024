export const UI = {
  key: {
    iconLeft: 'icon-[solar--qr-code-outline]',
    iconRight: 'icon-[solar--close-circle-outline]',
    placeholder: 'Enter Staff ID',
    wrapper: 'w-[350px] xl:w-[415px]',
  },
  scan: {
    iconLeft: 'icon-[solar--calculator-outline]',
    iconRight: '',
    placeholder: 'SCAN QR',
    wrapper: 'w-[350px] md:w-[755px]',
  },
};
