import { Component, inject } from '@angular/core';
import { VerifyService } from '../../service/verify.service';
import { today, yesterday } from 'clocker/shared/utils';
import { ShiftService } from 'clocker/shared/services';

@Component({
  selector: 'app-verify-out',
  templateUrl: './out.component.html',
  styleUrls: ['../../verify.component.css'],
})
export class VerifyOutComponent {
  public readonly verify = {
    service: inject(VerifyService),
  };

  public readonly shared = {
    shift: inject(ShiftService),
  };

  public today = today;
  public yesterday = yesterday;
}
