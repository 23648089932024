<mat-label>
  {{ props.label }}

  @if (props.required) {
    <span aria-hidden="true"> * </span>
  }
</mat-label>

<div
  class="mt-2 aspect-video rounded min-h-[100px]"
  [class]="field.props['class']"
  [class.border]="formControl.invalid && showError"
  [class.border-red-500]="formControl.invalid && showError"
>
  <group-ui-select-image
    [accept]="field.props['accept']"
    [isSecureImage]="field.props['isSecureImage']"
    [file]="file"
  ></group-ui-select-image>
</div>
